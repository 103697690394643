.mainContainer {
  height: 100%;
  display: flex;
}

.mainContainer.light {
  background: var(--bg, #F5F5F7);
  color: var(--black, #212121);
}

.mainContainer.dark {
  background: var(--colorBgContainer, rgba(20, 20, 20, 1));
  color: var(--white, #FFF) !important;
}

.pageContentContainer {
  margin-left: 24px;
  margin-top: 32px;
  margin-right: 16px;
  margin-bottom: 24px;
  /*margin-bottom: 0;*/
  /*height: 100%; *!*/
  display: flex;

  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  flex: 1;

}

.pageContentContainer.wideScreenContainer {
  margin: 0;
}

.cameraCheckWindow_ProgressBar {
  position: relative;
  width: 100%;
}

.pageTitle {
  color: var(--black, #212121);

  /* inter_25/25_sb_(32) */
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 128% */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pageContent {
  overflow-y: auto;
  /* margin-top: 24px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  flex-grow: 1;
}

.pageContent::-webkit-scrollbar {
  display: none;
}

.narrowPageContentContainer {
  margin: 0;
}

.language-selector {
  background: transparent;
  border: none;
  font-size: 16px;
  color: #9FAAB8 !important;
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 4px;
  font-style: normal;
  font-weight: 500;
}

.language-selector:focus {
  border: none;
  outline: none;
  color: black !important;
}

.language-selector>div {
  color: black;
  margin: 12px;
}

.language-dropdown {
  position: absolute;
  font-style: normal;
  font-weight: 500;
  right: 3rem;
  top: 4rem;
  display: flex;
  width: 179px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  color: var(--black, #212121);

  /* inter_16/16_regular_(24) */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  background: white;
  border-radius: 8px;
  background: var(--white, #FFF);

  /* shadow */
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}

.language-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.player-page-controller {
  max-width: 1648px;
  margin: auto;
}

@media screen and (max-width: 1440px) {
  .page-content {
      padding: 0 32px!important;
    }
}