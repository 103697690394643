.videoFiltersPanelController {
  /*position: fixed;*/
  display: flex;
  flex-direction: row;
  /* height: 100%; */
  /*right: 0px;*/
  /*top: 0px;*/
  /* border-left: solid 1px var(--colorBorder, rgba(66, 66, 66, 1)); */
  /* padding: 32px 16px; */
}

/* .videoFiltersPanelController::before {
  content: '';
  position: absolute;
  height: 96%;
  top: 2%;
  width: 1px;
  background-color: var(--colorBorder, rgba(66, 66, 66, 1));
  z-index: 10;
} */

.videoFiltersPanelController>div:nth-child(2) {
  /* background: var(--colorBgLayout, #292D32); */
  background: var(--colorBgContainer, rgba(20, 20, 20, 1));
  z-index: 2;
  width: 360px;
}

.playlists-side-bar {
  /* min-width: 260px;
  max-width: 260px; */
  display: flex;
  flex-direction: column;
  background: var(--colorBgLayout, #292D32);
}

.gameVideoContainer {
  /* box-sizing: unset; */
  align-items: flex-start;
  /*gap: 8px;*/
  background: rgba(255, 255, 255, 0.12);
  display: flex;
  /* height: 42px; */
  flex-direction: row;
  padding: 8px 12px 8px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
}

.collapse-header-class {
  flex: 1;
  overflow: hidden;
}

.collapse-header-class .ant-collapse-header {
  align-items: center !important;
  background: var(--colorFillSecondary, rgba(255, 255, 255, 0.12));
}

.gameVideoContainer-v2 {
  border-radius: 8px 8px 8px 8px;
}

.gameVideoContainer-collapsed,
.gameVideoContainer-v2>div.ant-collapse-item>div.ant-collapse-header {
  border-radius: 8px 8px 0px 0px !important;
}

.ant-collapse-header-text {
  overflow: hidden;
}

.gameVideoContainer-v2-collapsed>div.ant-collapse-item>div.ant-collapse-header {
  border-radius: 8px 8px 8px 8px !important;
}

/* .gameVideoContainer>div:nth-child(1){
    color: var(--new-main-light, #3A78F1);
    display: flex;
    padding: 4px 8px 8px 8px;
    align-self: stretch;
} */
.gameListContainer::-webkit-scrollbar {
  width: 3px;
  /* Width of the scrollbar */
  border-radius: 13px;
  background: var(--table-table-name, #9FAAB8);
  transition: opacity 0.5s ease;
}

.gameListContainer::-webkit-scrollbar-thumb {
  border-radius: 13px;
  position: absolute;
  left: 5px;
}

.gameListContainer::-webkit-scrollbar-track {
  background-color: var(--gray-gray);
  position: absolute;
  left: 5px;
}

.gameListContainer {
  position: relative;
  padding-bottom: 2rem;
  overflow-y: auto;
  height: 96.2%;
  scrollbar-color: var(--gray-gray);
}

.videoAttributesTooltip {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  stroke-width: 1px;
  border-radius: 8px;
  color: var(--white) !important;
  text-wrap: normal;
  white-space: normal;
  max-width: 200px;
}

.blueDot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #3A78F1;
}

.noEventsTitle {
  color: var(--white, #FFF);
  text-align: center;

  /* inter_16/16_sb_(24) */
  font-family: "SF Pro Text Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.noEventsContent {
  color: var(--table-table-name, #9FAAB8);
  text-align: center;

  /* inter_16/16_regular_(24) */
  font-family: "SFProTextRegular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.filtersPanelTopBar {
  padding: 8px 4px 8px 0px;
}

.filtersPanelTopBar_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.filtersPanelTopBar_content_topLine {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;

  /* H5/medium */
  overflow: hidden;
  color: var(--colorTextLabel, rgba(255, 255, 255, 0.65));
  text-overflow: ellipsis;

  /* Heading/Heading 5 */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.filtersPanelTopBar_content_topLine_buttons {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
}

.filtersPanelTopBar_content_topLine_buttons_icon {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.filtersPanelTopBar_content_matchLine {
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;

  overflow: hidden;
  text-overflow: ellipsis;

  /* Footnote/description */
  color: var(--colorTextTertiary, rgba(255, 255, 255, 0.45));

  /* SM/SM Normal */
  font-family: SFProTextRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.filtersPanelTopBar_content_matchLine_divider {
  width: 1px;
  height: 10px;
  background: rgba(255, 255, 255, 0.12);
}

.ant-btn.active {
  background: var(--character-disabled-amp-placeholder-25, rgba(255, 255, 255, 0.30));
}

.videoFiltersPanelController.visible {
  width: 0px;
  transform: translateX(100%);
  z-index: 3;
  transition: transform 0.5s ease, width 0.5s ease, z-index 0.5s ease;
}

.videoFiltersPanelController.hidden {
  width: fit-content;
  z-index: 3;
  transform: translateX(0%);
  transition: transform 0.5s ease, right 0.5s ease, width 0.5s ease, z-index 0.5s ease;
}

.playlists-side-bar.visible {
  width: 260px;
  z-index: 1;
  /* min-width: 260px;
  max-width: 260px; */
  transition: transform 0.5s ease, right 0.5s ease, width 0.5s ease, z-index 0.5s ease;
}

.playlists-side-bar.hidden {
  width: 0;
  /* max-width: 0;
  min-width: 0; */
  z-index: 1;
  transform: translateX(100%);
  transition: transform 0.5s ease, width 0.5s ease, z-index 0.5s ease;
}