.block-wrapper {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.block-wrapper.left {
  width: auto;
}

.block-wrapper.right {
  width: 444px;
}

.block-wrapper.stats,
.block-wrapper.best-players {
  min-height: 338px;
}

.block-title {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.block-title-h {
  color: var(--colorPrimaryText, #002C8C);

  /* Heading/Heading 5 */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.block-content {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.block-column {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;

  border-radius: 12px;
  /* border: 1px solid var(--colorBorder, rgba(0, 0, 0, 0.15)); */
  background: var(--colorBgElevated, #FFF);
}

.block-column-row {
  display: flex;
  padding: 4px 0px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.block-column-ttd {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--colorTextHeading, rgba(0, 0, 0, 0.88));
  text-overflow: ellipsis;

  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.block-column-avg {
  display: flex;
  width: 48px;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--controlItemBgHover, rgba(0, 0, 0, 0.04));
  color: var(--colorPrimaryText, #002C8C);
  text-align: center;
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  text-transform: uppercase;
}

.block-column-total {
  display: flex;
  width: 70px;
  height: 32px;
  padding: 0px 4px 0px 4px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  background: var(--controlOutline, #E6F4FF);

  color: var(--colorPrimaryBase, #1677FF);
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  text-transform: uppercase;
}

.block-column-total-icon {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.na-stat {
  background: var(--colorFillAlter, rgba(0, 0, 0, 0.02)) !important;
  color: var(--colorTextDisabled, rgba(0, 0, 0, 0.25)) !important;
  text-align: center !important;
  justify-content: center !important;
  text-transform: lowercase !important;
  /* SM/SM Normal */
  font-family: "SFProTextRegular" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px;
  /* 166.667% */
  border-radius: 8px;
}

.percent-value {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));

  /* Value/LG Normal */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  text-transform: uppercase;
}