.tournament-header>img{
    width: 40px;
    height: 40px;
}
.tournament-header{
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.tournament-header>div{
    display: flex;
    padding: 8px 0px;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;

    /* color: var(--colorPrimaryText, #002C8C);

    /* Heading/Heading 5 */
    /* font-family: "Roboto Condensed";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    /* letter-spacing: 0.96px;
    text-transform: uppercase; */
}
.tournament-table-header{
    display: flex;
    padding: 8px 12px 2px 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}
.tournament-table-header>div {
    overflow: hidden;
    color: var(--colorTextTertiary, rgba(0, 0, 0, 0.45));
    text-overflow: ellipsis;

    /* Base/Base Strong */
    font-family: "SF Pro Text Bold";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
}
.tournament-team-wrapper{
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--colorBgElevated, #FFF);

    /* boxShadow */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}
.tournament-team-wrapper.active,
.tournament-team-wrapper:hover{
    background: var(--colorPrimaryBg, #E6F4FF);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}
.tournament-team-position{
    width: 24px;
    flex-shrink: 0;

    color: var(--colorInfoText, #002C8C);
    text-align: center;
    font-family: "Roboto Condensed";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
}
.tournament-team-logo>img{
    width: 32px;
    height: 32px;
}
.tournament-team-name{
    flex: 1 0 0;
    overflow: hidden;
    color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
    text-overflow: ellipsis;
    font-family: "Roboto Condensed";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.98px;
    text-transform: uppercase;
}
.tournament-team-stats{
    min-width: 230px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: space-between;
    overflow: hidden;
    color: var(--colorTextHeading, rgba(0, 0, 0, 0.88));
    text-align: center;
    text-overflow: ellipsis;

    /* Base/Base Normal */
    font-family: "SFProTextRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
}
.tournament-team-stats>div:last-child{
    font-family: "SF Pro Text Bold";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
}