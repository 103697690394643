.block-content {
  display: flex;
  /* flex-direction: column; */
}

.team-match-wrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid transparent;
  background: var(--colorBgElevated, #FFF);
  flex: 1;
  gap: 12px;
  padding: 12px 8px;
}

.team-match-wrapper>div:nth-child(1) {
  height: 112px;

  align-items: center;
  display: flex;
  align-self: stretch;
  gap: 8px;
  justify-content: space-between;
  flex: 1 1;
}

.team-match-wrapper:hover,
.team-match-wrapper.pressed {
  border: 1px solid var(--controlItemBgActiveHover, #BAE0FF);
  background: #F2F9FF;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.team-match-wrapper>hr {
  display: flex;
  width: 1px;
  padding: 8px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
}

.match-participants-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 0 0;
  gap: 12px;
  justify-content: center;
}

.match-participants-container>div {
  overflow: hidden;
  color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
  text-align: right;
  text-overflow: ellipsis;
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.98px;
  text-transform: uppercase;
}

.match-details {
  display: flex;
  width: 160px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));

  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  text-wrap: nowrap;

}

.score {
  display: flex;
  gap: 4px;
  align-items: center;
}

.score>div {
  flex-shrink: 0;
  color: var(--colorPrimaryText, #002C8C);
  text-align: center;

  display: flex;
  width: 28px;
  height: 24px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: var(--colorBorderSecondary, rgba(0, 0, 0, 0.06));
  /* Heading/Heading 5 */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.buttons-controller {
  display: flex;
  width: 141px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  height: 100%;
}

.buttons-controller>div:hover,
.buttons-controller-active {
  width: 100%;
  justify-content: flex-start;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.06);
}

.buttons-controller>div {
  display: flex;
  padding: 0px 12px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  gap: 8px;
  height: 100%;
}

.rotate0 {
  transition: transform 0.5s ease;
}

.rotate90,
.ant-tree-switcher_open {
  transform: rotate(90deg);
  transition: transform 0.5s ease;
}

.ant-tree-switcher_open,
.ant-tree-switcher_close {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate180 {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.match-skills-container {
  display: flex;
  flex: 1;
  padding: 8px 8px 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--colorBorderBg, #FFF);
}

.match-skills-details {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-self: stretch;
}

.team-row {
  flex: 1;
}

.match-skills-details .team-row {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 8px;
  /* align-items: center; */
}

.team-row .team-name {
  overflow: hidden;
  height: 22px;
  color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
  text-overflow: ellipsis;
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.98px;
  text-transform: uppercase;
}

.stat-value-container {
  display: flex;
  min-width: 90px;
  padding: 0px 8px 0px 8px;
  /* justify-content: space-between; */
  flex-grow: 1;
  align-items: center;
  flex: 1 0 0;
  max-height: 40px;
  gap: 8px;
  border-radius: 8px;
  background: var(--controlItemBgActive, #E6F4FF);

  color: var(--colorPrimaryBase, #1677FF);
  /* Value/LG Strong */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  text-transform: uppercase;
}

.stat-value-container:hover {
  background: var(--controlItemBgActiveHover, #BAE0FF);
}

.stat-value-container-zero {
  display: flex;
  /* min-width: 70px; */
  padding: 0px 12px 0px 12px;
  align-items: center;
  flex: 1 0 0;
  /* max-height: 32px; */
  gap: 8px;
  border-radius: 8px;
  background: var(--controlItemBgHover, rgba(0, 0, 0, 0.04));
  color: var(--colorPrimaryText, #002C8C);

  /* Value/LG Strong */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  /* text-transform: uppercase; */
}

.stat-value-container:first-child {
  flex: 1 0 0;
  color: var(--colorPrimaryBase, #1677FF);
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  /* text-transform: uppercase; */
}

.disabled-stat-color {
  color: var(--colorTextDisabled, rgba(0, 0, 0, 0.25)) !important;
  text-align: center;
  text-transform: lowercase !important;
  /* SM/SM Normal */
  font-family: "SFProTextRegular" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  /* 166.667% */
}

.match-skills-teams {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.skill-title {
  overflow: hidden;
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  text-overflow: ellipsis;

  /* SM/SM Strong */
  font-family: "SF Pro Text Bold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 166.667% */
}

.stat-header {
  text-wrap: nowrap;
  /* overflow: hidden; */
  color: rgba(0, 0, 0, 0.45);
  text-overflow: ellipsis;

  /* SM/SM Strong */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 166.667% */
}

.stat-header>span {
  color: rgba(0, 0, 0, 0.45);
}

.match-stats-block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  /* row-gap: 0; */
  grid-auto-flow: row;
  grid-auto-rows: auto;
}

@media screen and (max-width: 1280px) {
  .match-stats-block {
    grid-template-rows: repeat(4, 1fr);
  }
}

.stat-average {
  color: rgba(0, 0, 0, 0.45) !important;

  /* Value/LG Normal */
  font-family: "Roboto Condensed", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  /* 150% */
  text-transform: lowercase !important;
  text-wrap: nowrap !important;
  /* text-align: end; */
}

.buttons-controller-disabled {
  color: var(--colorTextDescription) !important;
}