.helper-button-text {
  color: var(--colorTextDescription, rgba(255, 255, 255, 0.45));
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  /* text-transform: uppercase; */
  /* width: 18px; */
  flex-shrink: 0;
}