.best-players-wrapper {
    display: flex;
    flex: 1;
    padding: 8px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--colorBorder, rgba(0, 0, 0, 0.15));
    background: var(--colorBgElevated, #FFF);
}
.best-player-row{
    display: flex;
    flex-direction: column;
    max-height: 57px;
    min-width: 250px;
    padding-top: 8px;
    align-items: flex-start;
    align-self: stretch;
    flex: 1;
    justify-content: space-between;
}
.best-player-number {
    color: var(--colorInfoText, #002C8C);
    text-align: center;
    font-family: "Roboto Condensed";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
}
.best-player-name{
    flex: 1;
    overflow: hidden;
    color: var(--colorText, rgba(0, 0, 0, 0.88));
    text-overflow: ellipsis;

    /* Base/Base Normal */
    font-family: "SFProTextRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
}
.best-player-value{
    display: flex;
    width: 48px;
    min-height: 45px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--controlItemBgHover, rgba(0, 0, 0, 0.04));

    color: var(--colorPrimaryText, #002C8C);
    text-align: center;
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-transform: uppercase;
}
.best-player-divider{
    display: flex;
    width: 324px;
    height: 1px;
    justify-content: center;
    align-items: center;
    margin-left: 24px;
    background: var(--colorSplit, rgba(0, 0, 0, 0.06));
}
.ant-select-item-group.small-opt-group{
    height: 1px!important;
}
.best-players-no-data{
    color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
    text-align: center;

    /* Base/Base Normal */
    font-family: "SFProTextRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin: 0px 55px;
}