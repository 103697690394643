.team-header-block {
    z-index: 20;
    /* position: fixed; */
    top: 20;
    width: 100%;
    /* margin-left: -50px; */
    padding: 0 32px 16px 32px;
    background: rgba(255, 255, 255, 1);
    border-radius: 0px 0px 24px 24px;
    border: 1px solid var(--colorBorder, rgba(0, 0, 0, 0.15));
    border-top: 0;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}
.team-header-lineup{
    /* display: flex; */
    /* gap: 16px; */
    /* flex-wrap: wrap; */
    min-height: 210px;
    flex: 1;
    border-radius: 16px;
    border: 1px solid var(--colorBorder, rgba(0, 0, 0, 0.15));
    background: var(--colorItemTextDisabled, rgba(255, 255, 255, 0.25));

    padding: 8px 16px 12px 16px;
    /*align-items: flex-end;
    align-content: flex-end;
    gap: 0px 16px;
    flex: 1 0 0;
    flex-wrap: wrap;
    flex-direction: row; */
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    /* gap: 16px; */
    row-gap: 0;
    grid-auto-flow: row;
    grid-auto-rows: auto;


}
@media screen and (min-width: 1160px) {
    .team-header-lineup {
      grid-template-columns: repeat(4, 1fr); /* 5 columns for wider screens */
    }
  }
.team-header-player{
    
    /* flex-grow: grow; */
    max-height: 38px;
    box-sizing: border-box;
    display: flex;
    min-width: calc(25% - 16px);
    padding: 8px 0px;
    align-items: center;
    gap: 8px;
    /* flex: 1 0 0; */
    flex: 1 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    margin-bottom: -1px;
}
.team-header-player-number{
    border-radius: 6px;
    background: var(--colorBorderSecondary, rgba(0, 0, 0, 0.06));

    width: 24px;
    flex-shrink: 0;

    color: var(--colorPrimaryText, #002C8C);
    text-align: center;

    /* Value/Base Strong */
    font-family: "Roboto Condensed";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    text-transform: uppercase;
}
.team-header-player-name{
    flex: 1;
}
.team-header-logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 208px;
}
.team-header-logo>img {
    width: 116px;
    height: 116px;

}
.team-header-name{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--colorTextHeading, rgba(0, 0, 0, 0.88));
    text-align: center;
    text-overflow: ellipsis;

    /* Heading/Heading 4 */
    font-family: "Roboto Condensed";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    letter-spacing: 0.6px;
    text-transform: uppercase;
}
.team-header-league{
    align-self: stretch;
    color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
    text-align: center;

    /* LG/LG Normal */
    font-family: "SFProTextRegular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.see-more{
    display: flex;
    padding: 0px 16px 0px 12px;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5px;
    /* align-items: self-start; */
    gap: 8px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid var(--colorBorder, rgba(0, 0, 0, 0.15));
    background: #FFF;
    box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.04);

}
.team-header-player-name:hover{
    text-decoration: underline;
}